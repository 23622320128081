<template>
  <c-flex flex-dir="column">
    <Header />

    <c-box :max-w="['inherit', '1200px']" w="100%" mx="auto">
      <c-flex justify-content="center" max-w="1200px">
        <Sidemenu />
        <c-flex
          pt="42px"
          pl="30px"
          mb="130px"
          w="923px"
          flexGrow="1"
        >
          <router-view />
        </c-flex>
      </c-flex>
    </c-box>

    <Footer />
  </c-flex>
</template>

<script>
import Header from "@/components/header/index.vue";
import Sidemenu from "./sidemenu.vue";
import Footer from "@/components/Footer.vue";
import { mapState } from "vuex";
export default {
  name: "ProfileLayout",
  components: {
    Header,
    Sidemenu,
    Footer,
  },
  computed: {
    ...mapState({
      user: (s) => s.auth.user,
    }),
    isSuperadmin() {
      if (this.user.role !== "super_admin") {
        this.$router.replace("/");
      }
      if (this.user.role == "super_admin") return true;
      return false;
    },
  },
};
</script>

<style scoped>
[data-chakra-component="CBreadcrumb"] {
  color: var(--colors-brand-900);
}

[data-chakra-component="CBreadcrumb"]
  /deep/
  [data-chakra-component="CBreadcrumbItem"] {
  color: var(--colors-brand-900);
}
[data-chakra-component="CBreadcrumb"]
  /deep/
  [data-chakra-component="CBreadcrumbItem"]:last-child {
  color: #333;
}
</style>
