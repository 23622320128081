<template>
  <c-box
    v-if="footer"
    :display="['none', 'block']"
    class="footer"
    bgColor="#F2F2F2"
    w="100%"
    paddingTop="50px"
    paddingBottom="0"
  >
    <c-flex
      w="100%"
      maxWidth="1200px"
      mx="auto"
      justifyContent="space-between"
      :flexDirection="['column', 'row']"
    >
      <c-box
        :width="['100%', 1 / 4, 1 / 4]"
        paddingLeft="20px"
        paddingRight="20px"
        marginBottom="20px"
      >
        <c-link>
          <c-image src="/images/header-logo.png" alt="logo" />
        </c-link>
        <c-text
          fontSize="12px"
          mt="10px"
          mb="50px"
          color="superDarkGray.900"
          fontFamily="Roboto"
        >
          Dietela (PT. Gizi Sehat Indonesia)
        </c-text>
        <c-heading
          as="h3"
          color="brand.900"
          textTransform="uppercase"
          fontSize="14px"
          mb="20px"
        >
          Kontak Info
        </c-heading>
        <c-list>
          <c-list-item
            pos="relative"
            fontSize="14px"
            color="superDarkGray.900"
            fontFamily="Roboto"
            marginBottom="15px"
            paddingLeft="24px"
          >
            <c-image
              src="/images/icon-map-pin.png"
              pos="absolute"
              left="0"
              top="0"
            />
            {{ footer.address }}
          </c-list-item>
          <c-list-item
            pos="relative"
            fontSize="14px"
            color="superDarkGray.900"
            fontFamily="Roboto"
            marginBottom="15px"
            paddingLeft="24px"
          >
            <c-image
              src="/images/icon-phone.png"
              pos="absolute"
              left="0"
              top="0"
            />
            {{ footer.contact }}
          </c-list-item>
          <c-list-item
            pos="relative"
            fontSize="14px"
            color="superDarkGray.900"
            fontFamily="Roboto"
            marginBottom="15px"
            paddingLeft="24px"
          >
            <c-image
              src="/images/icon-mail.png"
              pos="absolute"
              left="0"
              top="0"
            />
            {{ footer.email }}
          </c-list-item>
        </c-list>
      </c-box>
      <c-box
        :width="['100%', 1 / 4, 1 / 4]"
        paddingLeft="20px"
        paddingRight="20px"
      >
        <c-heading
          as="h3"
          color="brand.900"
          textTransform="uppercase"
          fontSize="14px"
          mb="20px"
        >
          Social Media
        </c-heading>
        <c-list marginBottom="30px" display="flex">
          <c-list-item
            v-for="(item, index) in footer.socialMedia"
            :key="index"
            marginRight="10px"
          >
            <c-link :href="item.url" target="_blank">
              <c-image :src="iconSocmed(item.logo)" alt="socmed" />
            </c-link>
          </c-list-item>
        </c-list>
        <c-heading
          as="h3"
          color="brand.900"
          textTransform="uppercase"
          fontSize="14px"
          mb="20px"
        >
          Lainnya
        </c-heading>
        <c-list>
          <c-list-item
            fontSize="14px"
            color="superDarkGray.900"
            fontFamily="Roboto"
            marginBottom="15px"
          >
            <c-link
              as="router-link"
              :to="{ name: 'client.termcondition' }"
              :color="$route.name == 'client.termcondition' ? 'brand.900' : ''"
            >
              Terms and Conditions
            </c-link>
          </c-list-item>
          <c-list-item
            fontSize="14px"
            color="superDarkGray.900"
            fontFamily="Roboto"
            marginBottom="15px"
          >
            <c-link
              as="router-link"
              :to="{ name: 'client.privacypolicy' }"
              :color="$route.name == 'client.privacypolicy' ? 'brand.900' : ''"
            >
              Privacy Policy
            </c-link>
          </c-list-item>
        </c-list>
      </c-box>
    </c-flex>
    <c-flex
      justify="center"
      align="center"
      py="20px"
      marginTop="30px"
      borderTop="1px solid #C4C4C4"
    >
      <c-text color="brand.900" fontSize="12px" fontFamily="Roboto">
        Dietela (PT. Gizi Sehat Indonesia) © 2021 All right reserved
      </c-text>
    </c-flex>
  </c-box>
</template>

<script>
import { mapGetters } from "vuex";
import {
  CBox,
  CFlex,
  CLink,
  CImage,
  CText,
  CHeading,
  CList,
  CListItem,
} from "@chakra-ui/vue";
export default {
  name: "Footer",
  components: {
    CBox,
    CFlex,
    CLink,
    CImage,
    CText,
    CHeading,
    CList,
    CListItem,
  },
  data() {
    return {
      footer: "",
    };
  },
  computed: {
    ...mapGetters({
      axiosCMS: "customAxiosCMS",
    }),
  },
  async mounted() {
    this.loadFooter();
  },
  methods: {
    loadFooter() {
      this.axiosCMS.get("/footer").then((res) => {
        this.footer = res.data;
      });
    },
    iconSocmed(items) {
      if (items.length > 0) {
        return items[0].url;
      }
      return "";
    },
  },
};
</script>

