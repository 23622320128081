import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=1707fa14&"
import script from "./Footer.vue?vue&type=script&lang=js&"
export * from "./Footer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CImage: require('@chakra-ui/vue').CImage, CLink: require('@chakra-ui/vue').CLink, CText: require('@chakra-ui/vue').CText, CHeading: require('@chakra-ui/vue').CHeading, CListItem: require('@chakra-ui/vue').CListItem, CList: require('@chakra-ui/vue').CList, CBox: require('@chakra-ui/vue').CBox, CFlex: require('@chakra-ui/vue').CFlex})
